import React from "react"
import {Jumbotron} from 'react-bootstrap'
import Layout from "../components/layout"

      

class success extends React.Component {

    componentDidMount() {
       
        setTimeout(function () {
            window.location.assign('https://biniljacob.com/');
         }, 3000); //will call the function after 2 secs.

    }
  
    componentWillUnmount() {
  
    }
  
    render() {
      return (
        <Layout>
            <Jumbotron>
                <h3>Message Sent!!</h3>
                <p>
                Thank you for reaching out.
                </p><p>
                I will make sure to get back to you within 24 hours.
                </p>
                <p>
                You are going to be redirected to <a href="https://biniljacob.com">www.biniljacob.com</a>
                </p>
            </Jumbotron>
        </Layout>

      );
    }
}

export default success;
